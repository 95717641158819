<template>
  <div class="bigBox">
    <div class="search-top">
      <el-button type="primary" size="mini" @click="goToAdd">新增</el-button>
    </div>
    <div style="clear: both"></div>
    <div class="contant">
      <div v-for="(item, index) in tableList" :key="index" class="QAbox" @click="goToDetail(item)">
        <div class="top">
          <div class="title">
            <div><el-button type="primary" size="mini">Q</el-button></div>
            <div class="titleText">
              {{ item.title }}
            </div>
          </div>
          <div class="del">
            <el-link type="danger" :underline="false">删除</el-link>
          </div>
        </div>
        <div class="smallContent">
          {{ item.content }}
        </div>
      </div>
    </div>
    <div class="page-box mt20r">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="getTableDataList"
        :current-page.sync="listQuery.current"
        :page-sizes="[10, 20, 30]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      listQuery: {
        size: 10,
        current: 1,
      },
      total: 0,
      tableList: [
        {
          title: "常见问题1",
          content:
            "请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容",
        },
        {
          title: "常见问题2",
          content:
            "请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容",
        },
        {
          title: "常见问题3",
          content:
            "请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容请输入要描述的内容",
        },
      ],
    };
  },
  mounted() {},
  methods: {
    goToAdd() {
      this.$router.push("/home/concretecommonProblemDetailMannagement")
    },
    getTableDataList(val) {
      this.listQuery.current = val;
      this.getTableData();
    },
    handleSizeChange(val) {
      this.listQuery.size = val;
      this.listQuery.current = 1;
      this.getTableData();
    },
    goToDetail(data){
      this.$router.push("/home/concretecommonProblemDetailMannagement")
    },
    // 获取数据
    getTableData() {},
  },
};
</script>


<style lang="less" scoped>
.bigBox {
  margin: 20px;
  .contant {
    width: 100%;
    max-height: tableHeight;
    overflow: auto;
    .QAbox {
      width: 100%;
      border: 1px solid #ebeef5;
      box-sizing: border-box;
      margin-bottom: 20px;
      .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        border-bottom: 1px solid #ebeef5;
        .title {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-left: 60px;
          .titleText {
            margin-left: 20px;
            font-weight: 700;
            font-size: 18px;
          }
        }
        .del {
          margin-right: 10px;
        }
      }
      .smallContent {
        height: 60px;
        line-height: 30px;
        margin-left: 60px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        // 控制行数
        -webkit-line-clamp: 2;
       
      }
    }
  }
  .search-top {
    height: 40px;
    float: right;
  }
  .page-box {
    text-align: right;
  }
}
</style>